<template>
  <div id="header__login__buttons" v-click-outside="clickOutside">
    <span v-if="loggedIn" class="login__person__text">{{ userEmail }}</span>
    <Button
        :icon="loggedIn ? 'person' : 'person-outline'"
        id="person__button"
        variant="icon"
        @click="toggleExpanded(!expanded)"
        type="button"
        style="margin-left: 5px;"
    />
    <div v-show="expanded" class="login__options">
      <Button
          v-if="loggedIn && hasPermission('settings')"
          variant="primary"
          name="settings"
          border="none"
          icon="settings-outline"
          aria-label="settings"
          @click="navigateToMeinGenios();toggleExpanded(false)"
          :text="$t('genios.login.buttons.settings')"
          :text-style="400"
          :toggle-text="false"
          type="button"
          position="left"
      />
      <Mode
          @click="toggleExpanded(false)"
      />

      <Button
          v-if="loggedIn"
          id="logout__button"
          variant="primary"
          @click="logout();toggleExpanded(false)"
          name="logout"
          border="none"
          :text="$t('genios.login.buttons.logout')"
          :text-style="400"
          :toggle-text="false"
          type="button"
          icon="key-outline"
          position="left"
      />
      <Button
          v-else
          id="login__button"
          variant="primary"
          name="login"
          @click="login();toggleExpanded(false)"
          border="none"
          :text='$t("genios.login.buttons.login")'
          :text-style="400"
          :toggle-text="false"
          type="button"
          icon="key-outline"
          position="left"
      />
      <Button
          v-if="sharedState.showPreviousVersionLink"
          variant="primary"
          name="previous"
          border="none"
          aria-label="previous version"
          @click="goToOldVersion"
          :text="$t('genios.previousVersionLink')"
          :text-style="400"
          :toggle-text="false"
          type="button"
      />
    </div>

    <Button
        v-if="loggedIn && hasPermission('bookmark')"
        variant="icon"
        name="bookmark"
        icon="bookmark-outline"
        aria-label="bookmark"
        class="not_for_payment"
        @click="navigateToReferenceList()"
    />
    <Button
        v-if="(loggedIn) && hasPermission('monitoring')"
        variant="icon"
        name="alarm"
        icon="alarm-outline"
        aria-label="alarm"
        class="not_for_payment"
        @click="navigateToMonitoring()"
    />
  </div>
</template>

<script>
import axios from "axios";
import vClickOutside from "v-click-outside";

import {closeFullPopupModal, closeTransparentPopupModal} from "../../../functions/closing";
import updatePrices from "../../../functions/updatePrices";
import {checkLogin} from "../../../functions/checks";

import Button from "../styled/Button.vue";
import Mode from "./Mode.vue";
import {setupLoginComponent} from "../../../functions/setups/vue-components";
import {fetchNavigationTree, fetchUsersWatchlistBookmarks} from "../../../functions/fetching";
import {openTransparentPopupModal} from "../../../functions/opens";
import {hasPermission} from "../../../functions/utils/permission_utils";

export default {
  name: "LoginButtons",
  components: {Mode, Button},
  data() {
    return {
      expanded: false,
      userEmail: window.sharedState.defaultUserEmail
    };
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    sharedState: Object
  },
  computed: {
    loginStatus: function () {
      if (!!this.sharedState && !!this.sharedState.loginStatus) {
        return this.sharedState.loginStatus;
      }
      return this.staticLoginStatus();
    },
    loggedIn: function () {
      return this.loginStatus.loggedIn;
    }
  },
  watch: {
    loggedIn: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        fetchNavigationTree();
        updatePrices();
        this.updateDefaultUserEmail();
        if (newValue === true) {
          fetchUsersWatchlistBookmarks();
        } else {
          window.location.href = '/';
        }
      }
    },
    "sharedState.defaultUserEmail"(newValue) {
      this.userEmail = newValue;
    }
  },
  mounted() {
    this.$nextTick(() => {
      checkLogin();
      this.updateDefaultUserEmail();
    });
  },
  methods: {
    clearUserWatchlistBookmarks() {
      window.sharedState.watchlistDocumentIds = null;
      sessionStorage.setItem('watchlistDocumentIds', null);
    },
    updateDefaultUserEmail() {
      if (window.sharedState.loginStatus.loggedIn) {
        let path = "/api/getUserDefaultEmail";
        axios.get(path, {}).then((response) => {
          window.sharedState.defaultUserEmail = response.data;
          this.userEmail = response.data;
          sessionStorage.setItem('defaultUserEmail', response.data);
        }).catch(error => {
          console.log(error);
        });
      } else {
        window.sharedState.defaultUserEmail = "";
        sessionStorage.setItem('defaultUserEmail', "");
      }
    },
    toggleExpanded(value) {
      this.expanded = value;
    },
    clickOutside() {
      this.expanded = false;
    },
    navigateToMeinGenios() {
      let uriComponent = window.location.pathname + window.location.search;
      window.location.href = '/settings/show?src=refresh&redirectUrl=' + encodeURIComponent(uriComponent);
    },
    navigateToReferenceList() {
      let uriComponent = window.location.pathname + window.location.search;
      window.location.href = '/documentReference/referenceList?src=refresh&redirectUrl=' + encodeURIComponent(uriComponent);
    },
    navigateToMonitoring() {
      let uriComponent = window.location.pathname + window.location.search;
      window.location.href = '/alertNew/listAlertJobs?src=refresh&redirectUrl=' + encodeURIComponent(uriComponent);
    },
    login: function () {
      closeFullPopupModal(false, false);
      let callback = function () {
        closeTransparentPopupModal();
      };
      if (window.sharedState.page === "index") {
        callback = function () {
          setTimeout(function () {
            window.location.replace("/");
          }, 2000);
        };
      }

      if (window.sharedState.pathRoot === "document") {
        callback = function () {
          location.reload();
        };
      }

      const contentContainer = document.querySelector("#overlay_content");
      contentContainer.innerHTML = "<div id='embedded_login'></div>";
      // window.sharedState.loginStatus = false;
      openTransparentPopupModal();
      setupLoginComponent("#embedded_login", "genios.login.reasons.login", callback);
    },
    logout: async function () {
      closeFullPopupModal();
      closeTransparentPopupModal();
      this.clearUserWatchlistBookmarks();
      sessionStorage.clear();

      window.location.assign('/logout');
    },
    goToOldVersion: function () {
      window.location.href = "/dosearch?src=genios";
    },
    hasPermission: function (name) {
      return hasPermission(name)
    }
  }
};
</script>

<style scoped>
#header__login__buttons {
  display: flex;
  align-items: center;
  position: relative;
}
</style>
