<template>
    <EbibLoginComponent
        v-if="isEbib"
        :shared-state="sharedState"
        :callback="callback"
        :id="id"
        :key="id"
    />
    <StandardLoginComponent
        v-else
        :display-reason="displayReason"
        :show-further-info="showFurtherInfo"
        :shared-state="sharedState"
        :callback="callback"
        :id="id"
        :key="id"
    />
</template>

<script>
import loginMixin from "../../../functions/mixins/loginMixin";
import StandardLoginComponent from "./StandardLoginComponent.vue";
import EbibLoginComponent from "./EbibLoginComponent.vue";
import eventBus from "../../../eventBus";
import {checkLogin} from "../../../functions/checks";

export default {
  name: "LoginComponent",
  mixins: [loginMixin],
  components: {StandardLoginComponent, EbibLoginComponent},
  props: {
    displayReason: String,
    showFurtherInfo: {type: Boolean, defaultValue: true},
    sharedState: Object,
    callback: Function,
    id: String
  },
  data() {
    return {
      mySourcesItem: null,
    };
  },
  created() {
  },
  computed: {
    isEbib: function () {
      return this.solution === 'ebib';
    },
    solution: function() {
      return this.sharedState.solution;
    }
  },
  async mounted() {
    await eventBus.$on("setup-my-sources-item", item => {
      this.mySourcesItem = item;
    });
    await this.$nextTick(async () => {
      window.sharedState.loginStatus = await checkLogin();
      if (window.sharedState.loginStatus.loggedIn) {
        this.closeModal();
        if (this.callback) {
          this.callback();
        }
      }
    });
  },
  methods: {
  }
};
</script>
<style scoped>
</style>
