<template>
  <div class="enter">
    <!--suppress HtmlUnknownTarget -->
    <form class="enter__form login_form"
          v-if="!loginStatus.loggedIn"
          :aria-label="solution"
          @submit="checkAndSendForm($event, submitData)">
      <div class="enter__header">
        <div v-html="$t(displayReason)"></div>
      </div>

      <TextFormElement
          :element="inputUsername"
          :focus="focus"
          :passed-value="inputUsername.value"
          :error="(!!inputUsername.error || !!errors_any)"
          v-on:focus="focus = $event"
          v-on:modified="inputUsername.value = $event; submitData['username'] = $event"
      />
      <div class="enter__error">{{ inputUsername.error }}</div>


      <TextFormElement
          htmlInputFieldType="password"
          :element="inputPassword"
          :focus="focus"
          :passed-value="inputPassword.value"
          :error="(!!inputPassword.error || !!errors_any)"
          v-on:focus="focus = $event"
          v-on:modified="inputPassword.value = $event; submitData.password = $event"
      />
      <div class="enter__error">{{ inputPassword.error }}</div>


      <div class="enter__error">{{ errors_any }}</div>

      <a :href="getPasswordForgottenPath()" class="password_forgotten">{{
          $t('genios.login.labels.passwordForgot')
        }}</a>

      <CheckboxFormElement :element="rememberMeCheckboxObj"
                           :focus="focus"
                           v-on:focus="focus = $event"
                           @blur="focus = null"
                           @modified="rememberMeCheckboxObj.passedValue = $event; submitData.rememberMe = $event"/>

      <div class="enter__form__submit_container">
        <Button
            type="button"
            @click="closeModal"
            :text="$t('genios.login.labels.cancel')"
            variant="secondary"
            :toggle-text="false"
            style="margin-right: 15px;"
        />
        <Button
            name="submit"
            id="submit"
            type="submit"
            variant="primary"
            :text='$t("genios.login.buttons.login")'
            :toggle-text="false"
            style="margin: 0;"
        />
      </div>
    </form>
    <div
        class="login_form loading"
        v-else-if="!!callback"
    >
      <div class="loader"></div>
    </div>
    <div v-if="!$slots.default && showFurtherInfo" class="login_form furtherInfo">
      <h2>{{ $t("genios.login.labels.furtherInfo-header") }}</h2>
      <ul>
        <li v-for="(infoItem) in furtherInfo" :key="infoItem.id" class="furtherInfo_item">
          <ion-icon :name="infoItem.icon"></ion-icon>
          <span class="label">{{ $t("genios.login.labels.furtherInfo-text" + (infoItem.id)) }}</span>
        </li>
      </ul>
      <Button
          type="submit"
          :text='$t("genios.login.labels.furtherInfo-premium-btn")'
          :toggle-text="false"
          @click="showRegisterForm"
          variant="secondary--filled"
      />
    </div>
    <slot v-else/>
  </div>
</template>

<script>
import TextFormElement from "../FormElements/TextFormElement.vue";
import Button from "../styled/Button.vue";
import {openRegistrationPage} from "../../../functions/opens";
import CheckboxFormElement from "../FormElements/CheckboxFormElement.vue";
import loginMixin from "../../../functions/mixins/loginMixin";

export default {
  name: "StandardLoginComponent",
  mixins: [loginMixin],
  components: {Button, TextFormElement, CheckboxFormElement },
  props: {
    callback: Function,
    displayReason: String,
    showFurtherInfo: {type: Boolean, defaultValue: true},
    sharedState: Object
  },
  data() {
    return {
      focus: null,
      submitData: {
        username: "",
        password: "",
        embedded: true,
        rememberMe: false
      },
      furtherInfo: null
    };
  },
  created() {
    this.furtherInfo = [
      {id: 1, icon: 'thumbs-up-outline'},
      {id: 2, icon: 'alarm-outline'},
      {id: 3, icon: 'stats-chart-outline'},
      {id: 4, icon: 'rocket-outline'}
    ];
  },
  computed: {
    solution: function() {
      return this.sharedState.solution;
    }
  },
  async mounted() {

  },
  methods: {
    showRegisterForm() {
      openRegistrationPage();
    }
  }
};
</script>
<style scoped>
</style>