function getPermissionMap(permission) {
    if (permission == "SHOW_PERSONALIZATION_ICONS") {
        return ["source", "monitoring", "bookmark", "settings"]
    }

    return [];
}

export const hasPermission = function (name) {
    let permissionList = window.sharedState.permissions

    let i = 0;

    while (i < permissionList.length) {
        if(getPermissionMap(permissionList[i]).includes(name))
        {
            return true;
        }

        i++;
    }
    return false;
 }