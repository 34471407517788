import axios from "axios";
import {setupShareButton} from "./components/shareButton";
import {getDocumentPricesAsync} from "./services";

export default function updatePrices(elementToQuery = document.body, docId, callback) {
    updateDocumentPrices(elementToQuery, 0, docId, callback);
    updateEbookPrices(elementToQuery, docId);
    updateAttachmentButtonsText(elementToQuery, docId);
};

function updateEbookPrices(docBody, docId) {
    updateEbookAttachmentButtons(docBody, docId, false);
    updateEbookAttachmentButtons(docBody, null, true);    // update the chapter links
}

function updateAttachmentButtonsText(docBody, docId) {
    if (!docBody) docBody = document
    const buttons = docBody.querySelectorAll('[js-handler*="download_attachment"]');
    if (buttons && buttons.length > 0) {
        if (docId) {
            // if there is a docId collect all buttons that needs update
            const buttonsToUpdate = Array.from(buttons).map(button => {
                if (button.dataset.documentId === docId || button.dataset.legacyDocId === docId) {
                    return button;
                }
            }).filter(function (el) { // remove nulls
                return el != null;
            });
            // call web service once
            if (buttonsToUpdate.length) {
                fetchAttachmentButtonInfo(buttonsToUpdate[0])
                    .then(response => {
                        buttonsToUpdate.forEach(button => {
                            // update all affected buttons
                            updateButtonFromResponse(button, response);
                        });
                    });
            }
        } else {
            buttons.forEach(button => {
                fetchAttachmentButtonInfo(button)
                    .then(response => {
                        updateButtonFromResponse(button, response);
                    });
            });
        }
    }

    function fetchAttachmentButtonInfo(button) {
        return axios.get("/api/getAttachmentButtonText", {
            params: {
                attachmentType: button.dataset.attachmentType,
                documentId: button.dataset.legacyDocId,
                database: button.dataset.database,
                docType: button.dataset.docType
            }
        }).catch(exception => {
            console.log(exception);
        });
    }

    function updateButtonFromResponse(button, response) {
        if (!response) {
            return;
        }
        button.getElementsByClassName('button_title')[0].innerHTML = response.data.text;
        if (response.data.tooltip !== "") {
            button.getElementsByClassName('button_title')[0].title = response.data.tooltip;
        }
        let isPrimaryButton = button.classList.contains("button__primary");
        if (isPrimaryButton && (response.data.visited || response.data.paid)) {
            button.classList.replace("button__primary", "button__secondary");
        } else {
            if (response.data.visited) {
                button.classList.add("visited");
                button.classList.replace("button__primary", "button__secondary");
            } else if (response.data.paid) {
                button.classList.add("paid");
                button.classList.replace("button__primary", "button__secondary");
            }
        }
    }
}

function updateEbookAttachmentButtons(docBody, docId, isChapter) {
    if (!docBody) docBody = document
    let selector = isChapter ? '[js-handler*="download_ebook_chapter_attachment"]' : '[js-handler*="download_ebook_attachment"]';
    let buttons = docBody.querySelectorAll(selector);
    if (buttons && buttons.length > 0) {
        if (docId) {
            // if there is a docId collect all buttons that needs update
            buttons = Array.from(buttons).map(button => {
                if (button.dataset.documentId === docId || button.dataset.legacyDocId === docId) {
                    return button;
                }
            }).filter(function (el) { // remove nulls
                return el != null;
            });
        }
        buttons.forEach(button => {
            fetchEbookAttachmentButtonInfo(button, isChapter)
                .then(response => {
                    updateEbookAttachmentButtonFromResponse(button, response);
                });
        });
    }

    // For ex.: "/attachment/HAUF__9783648043493234?clientType=Beta&fileName=HAUF/2014/978-3-648-04349-3_007-008.pdf"
    function extractFilenameFromDownloadLink(attachmentDownloadLink) {
        let splittedParams = attachmentDownloadLink.split("&");
        let filenameString = "fileName=";
        let strlen = filenameString.length;
        for (let splittedParam of splittedParams) {
            if (splittedParam.startsWith(filenameString)) {
                return splittedParam.substring(strlen);
            }
        }
        return null;
    }

    function fetchEbookAttachmentButtonInfo(button, isChapter) {
        return axios.get("/api/getEbookAttachmentButtonText", {
            params: {
                attachmentType: button.dataset.attachmentType,
                docId: button.dataset.legacyDocId,
                attachmentDocId: button.dataset.attachmentDocId,
                isChapter: isChapter,
                index: button.dataset.index,
                filename: extractFilenameFromDownloadLink(button.dataset.attachmentDownloadLink)
            }
        }).catch(exception => {
            console.log("fetchEbookAttachmentButtonInfo: " + exception + " " + button.dataset);
        });
    }

    function updateEbookAttachmentButtonFromResponse(button, response) {
        if (!response) {
            return;
        }
        if (button.getElementsByClassName('price_placeholder')[0] !== undefined) {
            button.getElementsByClassName('price_placeholder')[0].innerHTML = response.data.text;
        } else if (button.getElementsByClassName('button_title')[0] !== undefined) {
            button.getElementsByClassName('button_title')[0].innerHTML = response.data.text;
            if (response.data.tooltip !== "") {
                button.getElementsByClassName('button_title')[0].title = response.data.tooltip;
            }
        }
        if (response.data.paid) {
            button.classList.add("paid");
            button.classList.replace("button__primary", "button__secondary");
        }
        if (response.data.visited) {
            button.classList.add("visited");
            button.classList.replace("button__primary", "button__secondary");
        }
    }
}

function updateDocumentPrices(docBody, depth = 0, docId = false, callback) {
    if (!docBody) docBody = document
    const links = docBody.querySelectorAll('[js-handler*="updatePrices"], .hyperlink-toc[js-handler*="openArticleOnClick"]') || []
    let documentLinks
    if (links.length)
        documentLinks = Array.from(links);
    let documentIdList = [];
    if (docId) {
        documentIdList.push(docId);
    }
    if (!docId && !!documentLinks) {
        documentIdList = Array.from(new Set(documentLinks
            .map(element => element.getAttribute("data-legacy-doc-id"))
            .filter(function (el) {  // remove nulls;
                return el != null;
            })));
    }

    const docList = window.sharedState?.searchResult?.filter(item => !item.infoPrice && (documentIdList.includes(item.documentId) || documentIdList.includes(item.legacyDocId))).map(item => {
        return {
            documentId: item.legacyDocId, database: item.database, priceCategory: item.priceCategory
        }
    });

    if (!!docList && docList.length > 0) {
        getDocumentPricesAsync(docList, (data) => {
            const isLoggedIn = window?.sharedState?.loginStatus?.loggedIn || false;

            const elementsToUpdate = docBody.querySelectorAll('[js-handler*="updatePrices"]');
            for (let item of elementsToUpdate) {

                let isButton = item.classList.contains("buy_button");
                if (isButton) {
                    const priceInfo = getPriceInfo({docBody, item, depth, data});
                    if (priceInfo) {
                        const isPrimaryButton = item.classList.contains("button__primary"),
                            {paid} = priceInfo;

                        if (isPrimaryButton && paid) {
                            item.classList.replace("button__primary", "button__secondary");
                            item.classList.add("paid");
                        } else if (!isLoggedIn && isPrimaryButton) {
                            item.classList.replace("button__secondary", "button__primary");
                        }
                        if (item.getAttribute("data-view-type") !== "grid" || paid) {
                            item.innerHTML = priceInfo.displayText;
                        }
                        item.classList.remove("hide");

                        item.dataset.price = priceInfo.price;
                        item.dataset.paid = priceInfo.paid;

                        if (!!callback) {
                            callback();
                        }
                    }
                }
            }

            let shareIconsToUpdate = docBody.querySelectorAll('[js-handler*="articleContentShare"]');
            for (let item of shareIconsToUpdate) {
                const priceInfo = getPriceInfo({docBody, item, depth, data});
                if (!priceInfo) {
                    if (priceInfo?.paid) {
                        item.classList.remove("disabled");
                        setupShareButton(item.parentElement);
                    }
                }
            }

            const documentChapters = docBody.querySelectorAll('.hyperlink-toc[js-handler*="openArticleOnClick"]');
            if (documentChapters.length)
                for (const item of documentChapters) {
                    const priceInfo = getPriceInfo({docBody, item, depth, data});
                    if (priceInfo) {
                        const {paid, visited} = priceInfo;
                        if (paid)
                            item.classList.add('visited');
                        if (visited)
                            item.classList.add('paid');
                    }
                }

            const openArticleOnClicks = docBody.querySelectorAll('[js-handler*="openArticleOnClick"]');
            if (openArticleOnClicks.length) {
                for (const item of openArticleOnClicks) {
                    const priceInfo = getPriceInfo({docBody, item, depth, data});
                    if (priceInfo) {
                        const {paid} = priceInfo;
                        if (paid) {
                            item.classList.remove('hide');
                        }
                    }
                }
            }
        })
    }
}

const getPriceInfo = ({item, data}) => {
    const legacyDocId = item.getAttribute("data-legacy-doc-id");
    const priceInfo = data[item.dataset.documentId] || data[legacyDocId]

    if (!priceInfo) {
        // console.log("ERROR: updateDocumentPrices: price-infos don't match documents on page");
        // // console.log("price-infos: " + JSON.stringify(data));
        // console.log("current docId to update: " + docId);
        //if (depth < 1) updateDocumentPrices(docBody, depth + 1);
        return false;
    }
    return priceInfo;
};
