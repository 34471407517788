import Vue from "vue";
import axios from "axios";

import {isUndefined} from "lodash";

import i18n from "../../../vue/components/plugins/Translations.vue";

import LoginComponent from "../../../vue/components/Login/LoginComponent.vue";
import SourceSearcher from "../../../vue/components/Browsing/SourceSearcher.vue";
import SearchComponent from "../../../vue/components/SearchComponent.vue";
import SidePanel from "../../../vue/components/SidePanel.vue";
import NavigationSelector from "../../../vue/components/NavigationSelector.vue";
import LoginButtons from "../../../vue/components/Login/LoginButtons.vue";
import ActiveFilters from "../../../vue/components/Filters/ActiveFilters.vue";
import ExtraNavigationLine from "../../../vue/components/ExtraNavigationLine.vue";
import SearchSettingsComponent from "../../../vue/components/SearchSettingsComponent.vue";
import ActionBarComponent from "../../../vue/components/ActionBar/ActionBarComponent.vue";
import ArchiveMonthPicker from "../../../vue/components/Browsing/ArchiveMonthPicker.vue";
import EPaperDownloadButton from "../../../vue/components/previewPane/EPaperDownloadButton.vue";
import FidoComponent from "../../../vue/components/FIDO/FidoComponent.vue";
import FidoNavigationBoxesComponent from "../../../vue/components/FIDO/FidoNavigationBoxesComponent.vue";
import MenuNavigation from "../../../vue/components/MenuNavigation.vue";
import WatchlistBookmarkToggleComponent from "../../../vue/components/Watchlist/WatchlistBookmarkToggleComponent.vue";
import PaymentComponent from "../../../vue/components/Payment/PaymentComponent.vue";
import SendEmailComponent from "../../../vue/components/ActionBar/SendEmailComponent.vue";
import {paymentForms, PaymentMode} from "../../components/payment";
import {getPaymentInfo} from "../../fetching";
import {getUnzerBasket} from "../../payment/unzerService";
import PressNotificationForm from "../../../vue/components/Monitoring/PressNotificationForm.vue";
import {buildPaymentApiCall} from "../../builds";
import QueryPopup from "../../../vue/components/QueryPopup/QueryPopup.vue";
import CompanyNotificationForm from "../../../vue/components/Monitoring/CompanyNotificationForm.vue";
import VideoComponent from "../../../vue/components/video/VideoComponent.vue";
import DocumentMetadata from "../../../vue/components/document/DocumentMetadata.vue";
import FullDocumentComponent from "../../../vue/components/QueryPopup/FullDocumentComponent.vue";
import SourceFilterComponent from "../../../vue/components/Filters/source/SourceFilterComponent.vue";
import vuetify from '../../../functions/plugins/vuetify';
import MySources from "../../../vue/components/MySources/MySources.vue";
import {SINGLE_POPUP_ID} from "../../components/mySourceComponent";
import TableOfContents from "../../../vue/components/document/TableOfContents.vue";

export default {
    components: {MySources}
}
// path to vuetify export

export const setupLoginComponent = function (querySelector, displayReason = '', callbackFunction = function () {
}, showFurtherInfo = true, id = "login") {
    const loginElement = document.querySelector(querySelector);
    if (!!loginElement) {
        new Vue({
            components: {
                loginComponent: LoginComponent
            },
            i18n,
            template: '<loginComponent :key="id" :id="id" :class="loginElement.className" :callback="callback" :shared-state="sharedState" :display-reason="displayReason" :show-further-info="showFurtherInfo"/>',
            propsData: {
                loginElement,
                displayReason: displayReason,
                showFurtherInfo: showFurtherInfo,
                sharedState: window.sharedState,
                callback: callbackFunction,
                id: id
            },
            props: {
                loginElement: HTMLDivElement,
                displayReason: String,
                sharedState: Object,
                callback: Function,
                showFurtherInfo: Boolean,
                id: String
            }
        }).$mount(loginElement);
    }
};

export const setupSourceSearcherComponent = function () {
    const sourceSearcher = document.getElementById("source-searcher");
    if (typeof (sourceSearcher) !== 'undefined' && sourceSearcher !== null) {
        new Vue({
            components: {
                sourceSearcher: SourceSearcher
            },
            i18n,
            el: '#source-searcher',
            template: '<source-searcher v-bind:shared-state="sharedState"/>',
            propsData: {
                sharedState: window.sharedState
            },
            props: {
                sharedState: Object
            }
        });
    }
};

export const setupSearchComponent = function () {
    const searchComponent = document.getElementById("search-component");
    if (typeof (searchComponent) !== 'undefined' && searchComponent !== null) {
        new Vue({
            name: "search",
            components: {
                searchComponent: SearchComponent
            },
            i18n,
            el: '#search-component',
            template: '<search-component v-bind:shared-state="sharedState"/>',
            propsData: {
                sharedState: window.sharedState
            },
            props: {
                sharedState: Object
            }
        });
    }
};

export const setupSidePanelComponent = function () {
    const sidePanel = document.getElementById("sidePanelComponent");
    if (typeof (sidePanel) !== 'undefined' && sidePanel !== null) {
        window.sidePanelVue = new Vue({
            vuetify,
            components: {
                sidePanel: SidePanel
            },
            i18n,
            el: '#sidePanelComponent',
            template: '<sidePanel v-bind:shared-state="sharedState"/>',
            propsData: {
                sharedState: window.sharedState
            },
            props: {
                sharedState: Object
            }
        });
    }
};

export const setupNavigationSelectorComponent = function () {
    const elementMTS = document.getElementById("navigation-selector");
    if (typeof (elementMTS) !== 'undefined' && elementMTS !== null) {
        new Vue({
            components: {
                navigationSelector: NavigationSelector
            },
            i18n,
            el: '#navigation-selector',
            template: '<navigation-selector \
            v-bind:shared-state="sharedState" \
        />',
            propsData: {
                sharedState: window.sharedState
            },
            props: {
                sharedState: Object
            }
        });
    }
};

export const setupLoginButtonsComponent = function () {
    const loginHeaderButtons = document.querySelector("#header__login__buttons");
    if (!!loginHeaderButtons) {
        new Vue({
            components: {
                loginButtons: LoginButtons
            },
            i18n,
            template: '<login-buttons :shared-state="sharedState"/>',
            propsData: {
                sharedState: window.sharedState
            },
            props: {
                sharedState: Object
            }
        }).$mount(loginHeaderButtons);
    }
};

export const setupResultVueComponents = function () {
    const activeFilters = document.getElementById("active_filters");
    if (typeof (activeFilters) !== 'undefined' && activeFilters !== null) {
        if (window.activeFilters !== undefined) {
            window.activeFilters.$destroy();
        }
        window.activeFilters = new Vue({
            components: {
                activeFilters: ActiveFilters
            },
            i18n,
            el: '#active_filters',
            template: '<active-filters v-bind:shared-state="sharedState" :replaceResultOnChange="true"/>',
            propsData: {
                sharedState: window.sharedState
            },
            props: {
                sharedState: Object
            }
        });

    }

    const extraNavigationLine = document.getElementById("extra_navigation_line");
    if (typeof (extraNavigationLine) !== 'undefined' && extraNavigationLine !== null) {
        if (window.extraNavigationLine !== undefined) {
            window.extraNavigationLine.$destroy();
        }
        window.extraNavigationLine = new Vue({
            components: {
                extraNavigationLine: ExtraNavigationLine
            },
            i18n,
            el: '#extra_navigation_line',
            template: '<extra-navigation-line v-bind:shared-state="sharedState"/>',
            propsData: {
                sharedState: window.sharedState
            },
            props: {
                sharedState: Object
            }
        });
    }

    const searchSettingsComponent = document.querySelector("#search-settings-component, #settings_menu_button");
    if (typeof (searchSettingsComponent) !== 'undefined' && searchSettingsComponent !== null) {
        if (window.searchSettingsComponent !== undefined) {
            window.searchSettingsComponent.$destroy();
        }
        window.searchSettingsComponent = new Vue({
            vuetify,
            components: {
                searchSettingsComponent: SearchSettingsComponent
            },
            i18n,
            el: searchSettingsComponent,
            template: '<search-settings-component v-bind:shared-state="sharedState"/>',
            propsData: {
                sharedState: window.sharedState
            },
            props: {
                sharedState: Object
            }
        });
    }

    const actionBarComponent = document.querySelector("#action-bar-component");
    if (typeof (actionBarComponent) !== 'undefined' && actionBarComponent !== null) {
        if (window.actionBarComponent !== undefined) {
            window.actionBarComponent.$destroy();
        }
        window.actionBarComponent = new Vue({
            components: {
                actionBarComponent: ActionBarComponent
            },
            i18n,
            el: actionBarComponent,
            template: '<action-bar-component v-bind:shared-state="sharedState" v-bind:mode="mode"/>',
            propsData: {
                sharedState: window.sharedState,
                mode: actionBarComponent.getAttribute("mode")
            },
            props: {
                sharedState: Object,
                mode: String
            }
        });
    }

};

export const setupArchiveMonthPickerComponent = function () {
    const archiveMonthPickerElement = document.querySelector("archive-month-picker");
    if (!!archiveMonthPickerElement) {
        new Vue({
            name: "archive-month-picker",
            components: {
                archiveMonthPicker: ArchiveMonthPicker
            },
            i18n,
            el: archiveMonthPickerElement,
            template: archiveMonthPickerElement.outerHTML
        });
    }
};

export const setupEPaperDownloadButtonComponent = function (previewElemToOpen) {
    const ePaperDownloadButtons = previewElemToOpen.querySelectorAll("e-paper-download-button");
    ePaperDownloadButtons.forEach(ePaperDownloadButton => {
        new Vue({
            name: "e-paper-download-button",
            components: {
                ePaperDownloadButton: EPaperDownloadButton
            },
            i18n,
            el: ePaperDownloadButton,
            propsData: {
                sharedState: window.sharedState
            },
            props: {
                sharedState: Object
            }
        });
    });
};

export const setupFidoComponent = function (isPreview = false) {
    const fidoComponents = document.querySelectorAll("fido-component") || [];

    fidoComponents.forEach(fidoComponent => {

        new Vue({
            name: "fido-component",
            components: {
                fidoComponent: FidoComponent
            },
            i18n,
            el: fidoComponent,
            propsData: {
                sharedState: window.sharedState,
                isPreview: isPreview
            },
            props: {
                sharedState: Object,
                isPreview: Boolean
            }
        });
    });
};


export const setupFidoList = function (previewElemToOpen, docId) {
    const parent = previewElemToOpen.querySelector('.media_preview__full_doc');
    if (!!parent) {
        parent.classList.remove('hide');
        const fidoList = parent.querySelector('.teaser__under_title');

        if (!!fidoList) {
            const store = {
                userInteracted: false,
                restoredBundleDocumentId: "",
                expandedListName: "FIRMENPROFIL",
                prevListName: "",
                nextListName: "HANDELSREGISTER",
                selectedItem: {itemId: "", paid: false},
                fidoBasicInfo: "",
                contactInformation: "",
                paidDocumentsMap: {
                    "FIRMENPROFIL": [],
                    "HANDELSREGISTER": [],
                    "JAHRESABSCHLUSS": [],
                    "BONITAETEN": [],
                    "VERFLECHTUNGEN": [],
                    "BRANCHENINFORMATIONEN": [],
                    "PRESSE": []
                },
                hasItemsInList: {
                    "FIRMENPROFIL": undefined,
                    "HANDELSREGISTER": undefined,
                    "JAHRESABSCHLUSS": undefined,
                    "CHARTS": undefined,
                    "BONITAETEN": undefined,
                    "VERFLECHTUNGEN": undefined,
                    "BRANCHENINFORMATIONEN": undefined,
                    "PRESSE": undefined
                },
                hasCharts: false
            }
            new Vue({
                name: "fido-navigation-boxes-component",
                components: {
                    fidoNavigationBoxesComponent: FidoNavigationBoxesComponent
                },
                i18n,
                el: fidoList,
                template: '<fido-navigation-boxes-component ' +
                    ' :update="false"' +
                    ' :shared-state="sharedState" ' +
                    ' restoredBundleDocumentId="test1" ' +
                    ' :userInteracted="false" ' +
                    ' :isPreview="true" ' +
                    ' expandedListName="test2"' +
                    ' fidoDocumentId="' + docId + '"' +
                    ' :has-charts="false"' +
                    ' :store="store"' +
                    '/>',
                propsData: {
                    sharedState: window.sharedState,
                    update: false,
                    restoredBundleDocumentId: '',
                    userInteracted: false,
                    expandedListName: '',
                    fidoDocumentId: docId,
                    hasCharts: false,
                    store
                },
                props: {
                    sharedState: Object,
                    update: Boolean,
                    restoredBundleDocumentId: String,
                    userInteracted: Boolean,
                    expandedListName: String,
                    fidoDocumentId: String,
                    hasCharts: Boolean,
                    store: Object
                }
            });
        }
    }

};

export const setupMenuNavigationComponent = function () {
    const element = document.getElementById("menuNavigationcomp");
    if (typeof (element) !== 'undefined' && element !== null) {
        window.menuNavigation = new Vue({
            components: {
                menuNavigation: MenuNavigation
            },
            i18n,
            el: '#menuNavigationcomp',
            template: '<menuNavigation :shared-state="sharedState"/>',
            propsData: {
                sharedState: window.sharedState
            },
            props: {
                sharedState: Object
            }
        });
    }
};

export const setupWatchlistBookmarkToggleComponent = function (documentId, documentTitle, readOnly = false) {
    return new Vue({
        name: "watchlist-bookmark-toggle",
        components: {
            watchlistBookmarkToggleComponent: WatchlistBookmarkToggleComponent
        },
        i18n,
        template: '<watchlistBookmarkToggleComponent v-bind:shared-state="sharedState" :document-id="documentId" :document-title="documentTitle"  :read-only="readOnly"/>',
        propsData: {
            sharedState: window.sharedState,
            documentId: documentId,
            documentTitle: documentTitle,
            readOnly: readOnly
        },
        props: {
            sharedState: Object,
            documentId: String,
            documentTitle: String,
            readOnly: Boolean
        }
    });
};

export const setupPaymentFormComponent = async function (data = null, documentId = null, mode = PaymentMode.fullDocument, retryOrder = () => {
}, paymentResources) {

    const paymentElement = document.querySelector('.payment') || null; //prevent executing multiple times if paymentComponent already exist in DOM.

    if (!paymentElement) {

        //needs in case of redirection between 2nd and 3rd steps
        if (!paymentResources)
            paymentResources = await getPaymentInfo();

        let paymentComponent;

        const urlParams = new URLSearchParams(window.location.search)

        //in case of opening document in a new tab. Only fort single document or attachment
        if (isUndefined(documentId) && window.sharedState.page === "document")
            documentId = window.sharedState.urlTopLevelNavigation;

        if (isUndefined(documentId) && urlParams.has('queryPopupId')) {
            documentId = urlParams.get("queryPopupId")
        }

        if (urlParams.has('video')) {
            paymentResources.videoId = urlParams.get('video')
        }

        //convert documentId string into Array. Its simplify usage of all components in payment components
        if (!Array.isArray(documentId))
            documentId = [documentId];

        if (data && data.code) {
            //part of buying document in preview
            paymentComponent = document.createElement('payment-component');
            paymentComponent.setAttribute(':order-response', JSON.stringify(data));
            paymentComponent.setAttribute(':document-ids', JSON.stringify(documentId));
            paymentComponent.setAttribute(':shared-state', JSON.stringify(window.sharedState));
            paymentComponent.setAttribute('mode', mode);
            document.body.appendChild(paymentComponent);
        } else if (!isUndefined(paymentResources?.paymentId) && !isUndefined(paymentResources?.orderDataId)) {
            //part of payment process to load all saved data back to PaymentComponent after redirection

            const {
                paymentId,
                orderDataId,
                basketId,
                state,
                isSuccess,
                merchantMessage,
                customerMessage,
                isUnzerError,
                videoId
            } = paymentResources;

            //get all documents for continue buying
            const basketInfo = await getUnzerBasket(basketId);
            const documentIds = basketInfo.basketItems.map(item => item.basketItemReferenceId);

            const currentMode = JSON.parse(sessionStorage.getItem('paymentId'))?.mode || PaymentMode.fullDocument;

            let issueDate = null, attachmentType = null;

            if (currentMode === PaymentMode.ePaper)
                issueDate = JSON.parse(sessionStorage.getItem('paymentId'))?.issueDate || null;

            if (currentMode === PaymentMode.attachment)
                attachmentType = JSON.parse(sessionStorage.getItem('paymentId'))?.attachmentType || null;

            //orderQuantity need only to define how many times per current session was  started payment process.
            // Also uses in redirection in case of first buying in session
            const orderQuantity = JSON.parse(sessionStorage.getItem('paymentId'))?.orderQuantity || 0;
            const sofortParams = JSON.parse(sessionStorage.getItem('paymentId'))?.sofortParams || {};

            //reset all data after loading necessary data. Probably this part need to move to TermsAndConditionsComponent after submiting
            sessionStorage.setItem('paymentId', JSON.stringify({paymentId: null, orderQuantity, mode: null}));

            let params = {
                ids: documentIds,
                videoId
            };

            if (merchantMessage) {
                params.merchantMessage = merchantMessage;
            }

            if (state !== 'canceled')
                params.orderDataId = orderDataId;

            if ("true" !== isSuccess) {
                params.orderDataId = null;
            }

            if (state === "completed")
                params = {...params, ...sofortParams};

            const {fetchUrl, params: buildParams, config} = buildPaymentApiCall(documentIds, currentMode, {
                issueDate,
                attachmentType
            }, params);

            //getting userDataModel and code. Should be TRANSACTION_IN_PROGRESS
            try {
                const result = await axios.post(fetchUrl, buildParams, config);
                const contentType = result.headers['content-type'];
                let paymentParams = {paymentId, issueDate, videoId}
                if (contentType && contentType.indexOf("application/json") !== -1)
                    paymentParams = {...paymentParams, ...result.data};
                if (!result.data?.orderDataId) paymentParams.orderDataId = orderDataId;

                if (isUnzerError === "true") {
                    paymentParams = {
                        ...paymentParams,
                        validationModel: {message: customerMessage},
                        code: paymentForms.ERROR_UNZER
                    }
                }

                paymentComponent = document.createElement('payment-component');
                paymentComponent.setAttribute(':order-response', JSON.stringify(paymentParams));
                paymentComponent.setAttribute(':document-ids', JSON.stringify(documentIds));
                paymentComponent.setAttribute(':shared-state', JSON.stringify(window.sharedState));
                paymentComponent.setAttribute('mode', currentMode);
                document.body.appendChild(paymentComponent);

            } catch (error) {
                console.log("setup payment error", error)
            }
        } else {
            //part when getting document.ftlh. Mostly only after opening document in full popup
            paymentComponent = document.querySelector("payment-component");
        }
        if (!!paymentComponent) {
            new Vue({
                name: "payment-component",
                components: {PaymentComponent},
                i18n,
                el: paymentComponent,
                propsData: {documentIds: documentId, mode, sharedState: window.sharedState},
                props: {
                    documentIds: Array,
                    mode: String,
                    sharedState: Object
                }
            });
        }
    }
};

export const setupSendEmailComponent = function (ids = [], selector = document, destroy = false) {
    const SendEmailClass = Vue.extend(SendEmailComponent);
    const sendEmailFormInstance = new SendEmailClass({
        i18n,
        propsData: {
            sharedState: window.sharedState,
            ids,
            expandedSendEmail: true,
            destroy
        }
    });
    sendEmailFormInstance.$mount();
    selector.appendChild(sendEmailFormInstance.$el);
};

export const setupPressNotificationComponent = function (querySelector, currentTarget) {
    const div = document.querySelector(querySelector);
    if (!!div) {
        new Vue({
            components: {
                pressNotificationForm: PressNotificationForm
            },
            i18n,
            template: '<press-notification-form :db-shortcut="dbShortcut" :source-name="sourceName" :shared-state="sharedState"/>',
            propsData: {
                dbShortcut: currentTarget.dataset.shortcut,
                sourceName: currentTarget.dataset.sourceName,
                sharedState: window.sharedState
            },
            props: {
                dbShortcut: String,
                sourceName: String,
                sharedState: Object
            }
        }).$mount(div);
    }
};

export const setupCompanyNotificationComponent = function (querySelector, currentTarget) {
    const div = document.querySelector(querySelector);
    if (!!div) {
        new Vue({
            components: {
                companyNotificationForm: CompanyNotificationForm
            },
            i18n,
            template: '<company-notification-form :company-name="companyName" :hnumber="hnumber"/>',
            propsData: {
                companyName: currentTarget.dataset.companyName,
                hnumber: currentTarget.dataset.hnumber
            },
            props: {
                companyName: String,
                hnumber: String
            }
        }).$mount(div);
    }
};

export const setupQueryPopupComponent = (query, label, dbShortcuts) => {
    const queryPopup = document.querySelector('#queryPopup')
    if (!!queryPopup)
        new Vue({
            components: {
                queryPopup: QueryPopup
            },
            i18n,
            template: '<query-popup :shared-state="sharedState" :query="query" :label="label" :db-shortcuts="dbShortcuts"/>',
            propsData: {
                query,
                label,
                dbShortcuts,
                sharedState: window.sharedState
            },
            props: {
                query: String,
                label: String,
                dbShortcuts: Array,
                sharedState: Object
            }
        }).$mount(queryPopup)
}

export const setupVideoComponent = (docBody = document, videoPartialId) => {
    const videoComponent = docBody?.querySelector('video-component')
    if (!!videoComponent)
        new Vue({
            components: {
                videoComponent: VideoComponent
            },
            i18n,
            el: videoComponent,
            propsData: {
                sharedState: window.sharedState,
                videoPartialId
            },
            props: {
                sharedState: [Object, String],
                videoPartialId: String
            },
        })
}

export const setupDocumentMetadataComponent = (docBody = document, videoPartialId) => {
    const documentMetadataComponent = docBody.querySelector('document-metadata')
    if (!!documentMetadataComponent)
        new Vue({
            components: {
                documentMetadata: DocumentMetadata
            },
            i18n,
            el: documentMetadataComponent,
            propsData: {
                sharedState: window.sharedState,
                videoPartialId
            },
            props: {
                sharedState: [Object, String],
                videoPartialId: String
            }
        })
}
export const setupFullDocumentComponent = async (documentId, params = {}) => {

    if (!documentId) {
        return;
    }
    const {data} = await axios.post(`/api/retrieveDocuments`, {
        ids: [documentId],
        forceConfirmPrice: false,
        ...params
    });

    if (data?.code) {
        await setupPaymentFormComponent(data, documentId, PaymentMode.queryPopup);
    } else {
        const content = document.querySelector('.content')

        if (!!content) {

            const fullDocument = document.querySelector('.full-document') || null

            if (!!documentId) {

                if (!!fullDocument) content.parentNode.removeChild(fullDocument)

                const fullDocumentComponent = document.createElement('div')
                fullDocumentComponent.setAttribute('id', 'fullDocumentComponent')

                content.parentNode.append(fullDocumentComponent)

                new Vue({
                    components: {
                        fullDocumentComponent: FullDocumentComponent
                    },
                    i18n,
                    template: '<full-document-component :shared-state="sharedState" :document-id="documentId"/>',
                    propsData: {
                        documentId,
                        sharedState: window.sharedState,
                    },
                    props: {
                        sharedState: Object,
                        documentId: String,
                    }
                }).$mount(fullDocumentComponent)
            } else {
                if (!!fullDocument)
                    content?.parentNode.removeChild(fullDocument)
            }
        }
    }
}

export const setupSourceFilterComponent = () => {
    const sourceFilterComponent = document.querySelector("#source-filter-component")
    if (!!sourceFilterComponent) {
        new Vue({
            components: {
                sourceFilterComponent: SourceFilterComponent
            },
            i18n,
            template: '<source-filter-component :shared-state="sharedState"></source-filter-component>',
            propsData: {
                sharedState: window.sharedState,
            },
            props: {
                sharedState: Object
            }
        }).$mount(sourceFilterComponent)
    }
}


export const setupMySourcesComponent = (parent, {currentMode, primaryBtnTextCode, textCode, data, showContent, dbShortcut}) => {
    const mySourcesTemplate = parent.querySelector(`#${SINGLE_POPUP_ID}_${dbShortcut}`)

    if (!!mySourcesTemplate)
        new Vue({
            name: "MySources",
            components: {
                mySources: MySources
            },
            i18n,
            template: `
              <my-sources
                  key="my-sources-current-component_${dbShortcut}"
                  id="${SINGLE_POPUP_ID}_${dbShortcut}"
                  :shared-state="sharedState"
                  :my-sources-data="mySourcesData"
                  :current-mode="currentMode"
                  :primary-btn-text-code="primaryBtnTextCode"
                  :text-code="textCode"
                  :show-content="showContent"
                  :db-shortcut="dbShortcut"
              ></my-sources>`,
            propsData: {
                currentMode,
                primaryBtnTextCode,
                textCode,
                showContent,
                dbShortcut,
                sharedState: window.sharedState,
                mySourcesData: data
            },
            props: {
                currentMode: String,
                primaryBtnTextCode: String,
                textCode: String,
                showContent: Boolean,
                sharedState: Object,
                mySourcesData: Array,
                dbShortcut: [String, null]
            }
        }).$mount(mySourcesTemplate)
}

export const setupTableOfContents = (id, sourceShortcut, issueId, useDate) => {
    const tableOfContents = document.querySelector("#table-of-contents-component-"+sourceShortcut)
    if (!!tableOfContents) {
        new Vue({
            components: {
                tableOfContents: TableOfContents
            },
            i18n,
            template: '<table-of-contents :source-shortcut="sourceShortcut" :issue-id="issueId" :use-date="useDate" :shared-state="sharedState"></table-of-contents>',
            propsData: {
                sourceShortcut,
                issueId,
                useDate,
                sharedState: window.sharedState,
            },
            props: {
                sourceShortcut: String,
                issueId: String,
                useDate: String,
                sharedState: Object
            }
        }).$mount(tableOfContents)
    }
}