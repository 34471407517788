export const getCookie = function (name) {
    const cookies = document.cookie.split(';');
    for (const item of cookies) {
        const pair = item.trim().split('=');
        if (pair[0] === name)
            return pair[1];
    }
    return null;
}

export const removeCookie = name => {
    const cookies = document.cookie.split(';')
    cookies.forEach((item, index) => {
        const pair = item.trim().split('=')
        if (pair[0] === name) {
            cookies.splice(index, 1)
        }
    })
    document.cookie = cookies.join(';')
}

export function getAuthCookieCacheBuster() {
    // added to queries to stop getting cached values when logging in / out
    switch (window.sharedState.activeProfile) {
        case "prod" : {
            return getCookie("GeniosAuth") || ''
        }
        case "acc" : {
            return getCookie("GeniosAuthACC") || ''
        }
        default : {
            return getCookie("GeniosAuthDEV") || ''
        }
    }
}
