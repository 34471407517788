<template>
  <div
      class="form_element dropdown"
      :class="{error, 'dropdown-opened': expanded}"
      tabindex="0"
      @click="toggleDropdown"
      @blur="clickOutside"
      @focus="setFocus"
  >
    <div class="select-header"
         :class="{'dropdown-label': expanded || currentOption.text.length > 0}"
    >
      <span v-show="element.fieldLabel && element.fieldLabel.length" class="label">{{ element.fieldLabel }}</span>
      <p class="selected-option" :class="{'dropdown-opened': expanded}" v-show="currentOption.text.length > 0">
        {{ currentOption.text }}</p>
    </div>
    <ion-icon
        class="dropdown-indicator md hydrated"
        src="/icons/custom/chevron-down-outline.svg"
        :class="this.expanded ? 'rotate-180' : 'rotate-0'"
    />
    <ul class="dropdown__options" v-show="expanded">
      <li class="dropdown__option" v-for="option in element.options" @click="sendData(option)">
        {{ option.text }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "DropdownFormElement",
  data() {
    return {
      currentOption: this.passedValue,
      value: '',
      expanded: false
    }
  },
  props: {
    element: Object,
    focus: [String, Number],
    passedValue: Object,
    error: {
      type: Boolean,
      default: false
    },
    scrollIntoView: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    passedValue: function (val) {
      this.currentOption = {...val}
    },
    error(newValue) {
      this.error = newValue
    }
  },
  methods: {
    setFocus() {
      this.$emit('focus', this.element.id)
    },
    sendData(data) {
      this.currentOption = {...data}
      this.$emit('modified', data)
    },
    toggleDropdown() {
      if (this.scrollIntoView) {
        const element = this.$el

        element.parentNode.scrollTop = element.offsetTop - 2 * element.offsetHeight;
      }

      this.expanded = !this.expanded
    },
    clickOutside() {
      setTimeout( ()=> this.expanded = false, 100 ); // hack: blur event cancelled by click event leaving dropdown open in Chrome+IE
    }
  }
}
</script>

<style>
.error {
  border-color: #CC1D33 !important;
}
</style>
