import i18n from "../../vue/components/plugins/Translations.vue";
import {closeFullPopupModal, closeTransparentPopupModal} from "../closing";
import {getPasswordForgottenPath} from "../opens";
import {login} from "../services/authApi";
import {setupFullLoadModuleMySources, sourceIconState} from "../components/mySourceComponent";
import {setupMySourcesPopupToggleHandler} from "../setups/handlers";

export default {
    data() {
        return {
            rememberMeCheckboxObj: {
                id: 'rememberMe',
                passedValue: false,
                fieldLabel: i18n.t("genios.login.labels.rememberMe")
            },
            inputUsername: {
                id: 1,
                field: 'username',
                fieldType: 'TEXT',
                fieldLabel: i18n.t("genios.login.labels.username") + '*',
                value: "",
                error: ""
            },
            inputPassword: {
                id: 2,
                field: 'password',
                fieldType: 'TEXT',
                fieldLabel: i18n.t("genios.login.labels.password") + '*',
                value: "",
                error: ""
            },
            termsAndConditions: {
                id: 'termsAndConditions',
                value: false,
                field: 'termsAndConditions',
                fieldLabel: i18n.t("genios.login.labels.termsAndConditions")
            },
            privacyPolicy: {
                id: 'privacyPolicy',
                value: false,
                field: 'privacyPolicy',
                fieldLabel: i18n.t("genios.login.labels.privacyPolicy")
            },
            ebibFields: {
                id: 'ebibFields',
                value: false,
                field: 'ebibFields',
                fieldLabel: i18n.t("genios.login.labels.dropdown") + '*'
            }
        }
    },
    computed: {
        loginStatus: function () {
            if (!!this.sharedState && !!this.sharedState.loginStatus) {
                return this.sharedState.loginStatus;
            }
            return {loggedIn: false};
        },
        loginStatusError: function () {
            return !!this.loginStatus.error;
        },
        errors_any: function () {
            return this.loginStatusError ? i18n.t("genios.login.errors.failed") : "";
        }
    },
    methods: {
        closeModal() {
            //don't need to update prices - they are updated by LoginButtons when login status changes
            closeFullPopupModal(false, false);
            closeTransparentPopupModal();
        },
        getPasswordForgottenPath() {
            return getPasswordForgottenPath();
        },
        buildRequestData(submitObject) {
            const data = new URLSearchParams();
            for (const [key, value] of Object.entries(submitObject)) {
                data.append(key, value);
            }
            return data;
        },
        validateLoginForm({invalidUsernameError= null} = {}) {
            let isValid = true;
            this.inputUsername.error = "";
            this.inputPassword.error = "";

            if (!this.inputUsername.value) {
                isValid = false;
                this.inputUsername.error = invalidUsernameError || i18n.t("genios.login.errors.username");//this.sharedState.ebibList[0] && this.sharedState.ebibList[0].errorText ? this.sharedState.ebibList[0].errorText : i18n.t("genios.login.errors.username");
            }
            if (!this.inputPassword.value) {
                isValid = false;
                this.inputPassword.error = i18n.t("genios.login.errors.password");
            }
            if (this.solution === "ebib") {
                this.privacyPolicy.error = "";
                if (this.privacyPolicy.value === false) {
                    isValid = false;
                    this.privacyPolicy = {...this.privacyPolicy, error: i18n.t("genios.login.errors.privacyPolicy")};
                }
                this.termsAndConditions.error = "";
                if (this.termsAndConditions.value === false) {
                    isValid = false;
                    this.termsAndConditions = {...this.termsAndConditions,error:  i18n.t("genios.login.errors.termsAndConditions")};
                }
                if (this.ebibFields.options.length > 1  && !this.ebibFields.value) {
                    isValid = false;
                    this.ebibFields = {...this.ebibFields,error:  i18n.t("genios.login.labels.dropdown")};
                }
            }

            return isValid;
        },
        async checkAndSendForm(e, submitData, validationOptions = {}) {
            e.preventDefault();
            e.stopPropagation();

            const isValid = this.validateLoginForm(validationOptions);
            if (isValid) {
                const data = this.buildRequestData(submitData);
                const loginStatus = await login(data);
                window.sharedState.loginStatus = loginStatus;
                window.sharedState.permissions = loginStatus?.permissions

                if (loginStatus.loggedIn) {
                    if (this.sharedState.page === "browse") {
                        setupFullLoadModuleMySources(this.mySourcesItem).then(() => {
                            if (!!this.mySourcesItem) {
                                setupMySourcesPopupToggleHandler(this.mySourcesItem, sourceIconState.SERVER);
                            }
                        });
                    }
                }

                if (this.callback && loginStatus.loggedIn) {
                    this.callback();
                }
            }
        }
    }
}