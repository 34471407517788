export const API_GET_DOCUMENT_PRICES = '/api/getDocumentPrices'
export const API_GET_VIDEO_PRICES = '/api/getVideoPrices'
export const API_RETRIEVE_DOCUMENT = "/api/retrieveDocument/"
export const API_RETRIEVE_DOCUMENTS = "/api/retrieveDocuments"

const MY_SOURCE_BASE_URL = "/api/mySources"
export const API_MY_SOURCES_CREATE = MY_SOURCE_BASE_URL + "/create"
export const API_MY_SOURCES_UPDATE = MY_SOURCE_BASE_URL + "/rename"
export const API_MY_SOURCES_DELETE = MY_SOURCE_BASE_URL + "/delete"

//auth
export const API_LOGIN = "/api/login"
